<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList3 } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
export default {
  name: 'scenic-areas-tickets',
  data() {
    return {
      ...api.command.getState(),
      myset: null,
    }
  },
  mixins: [scenicAreasTicketsMixin],
  mounted() {
    this.getInit()
  },
  destroyed() {
    clearInterval(this.myset)
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/ticketOrder/page',
        current: 1,
      })
      //   .then(result => {
      //     console.log(this.records)
      //     for (let i in this.records) {
      //       if (this.records[i].status === '5') {
      //         this.records[i].countDownTime = ''
      //         this.records[i].endTime = '2022-11-13 19:06:36'
      //         // this.countDown(i)
      //         console.log(i, this.records[i])
      //       }
      //     }
      //   })
    },
    // 倒计时
    countDownFun(time) {
      let startTime = new Date() // 当前时间
      let end = new Date(time) // 结束时间
      // console.log(end)
      let result = parseInt((end - startTime) / 1000) // 计算出豪秒
      let d = parseInt(result / (24 * 60 * 60)) // 用总共的秒数除以1天的秒数
      let h = parseInt((result / (60 * 60)) % 24) // 精确小时，用去余
      let m = parseInt((result / 60) % 60) // 剩余分钟就是用1小时等于60分钟进行趣余
      let s = parseInt(result % 60)
      // 当倒计时结束时，改变内容
      if (result <= 0) {
        // 重新请求列表
        return ''
      }
      if (h < 10) {
        h = '0' + h
      }
      if (s < 10) {
        s = '0' + s
      }
      if (h == 0 && m == 0) {
        return s
      } else if (h == 0) {
        return m + ':' + s
      } else if (d == 0) {
        return h + ':' + m + ':' + s
      } else {
        return d + ':' + h + ':' + m + ':' + s
      }
    },
    // 页面多个倒计时 归零时清除
    countDown(i) {
      let that = this
      let item = that.records[i]
      that.records[i].countDownFn = setInterval(() => {
        if (that.countDownFun(item.countDownTime) == '倒计时结束') {
          clearInterval(that.records[i].countDownFn) // 清除定时器
        } else {
          item.countDownTime = that.countDownFun(item.endTime)
          that.$set(that.list, item.countDownTime, that.countDownFun(item.endTime))
          that.$forceUpdate()
        }
      }, 1000)
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入手机号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName',
        },
      ]
    },
    // // 获取选中订单详情
    // getDetailData(orderId, orderItemId) {
    //   return new Promise((resolve, reject) => {
    //     getAction(`/api/ticketOrder/detail?orderId=${orderId}&orderItemId=${orderItemId}`)
    //       .then((result) => {
    //         resolve(result.data)
    //       })
    //       .catch((e) => reject && reject(e))
    //   })
    // },
    // // 获取套餐详情
    // getPackageDetail(id) {
    //   return new Promise((resolve, reject) => {
    //     getAction(`/api/combo/order/detail?id=${id}`)
    //       .then((result) => {
    //         resolve(result.data)
    //       })
    //       .catch((e) => reject && reject(e))
    //   })
    // },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record,
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/farming/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '商品数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => {
                  return (
                    <div class="product-order-item">
                      <span>{e.productQuantity}</span>
                      {!!records.refundCount && <span>（已退: {records.refundCount}）</span>}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'salesJsAmount',
          title: '结算金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{(records.salesJsAmount || 0).toFixed(2)}</span>
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{records.payAmount.toFixed(2)}</span>
                {!!records.refundAmount && <span>（已退: {records.refundAmount.toFixed(2)}）</span>}
              </div>
            )
          },
        },

        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          type: 'badge',
          // customRender: (text) => (orderStateList3.find((e) => e.value == text) || { text: '' }).text,
          onExport: (text) => (orderStateList3.find((e) => e.value == text) || { text: '' }).text,
          filterMultiple: false,
          filters: orderStateList3,
          render(data, records) {
            return {
              // showDot: true,
              name: records.refundId ? '待退款' : (orderStateList3.find((e) => e.value == data) || { text: '' }).text,
              color: records.refundId ? 'red' : 'transparent',
            }
          },
        },
        {
          dataIndex: 'buyPhone',
          title: '手机号',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'orderType',
          title: '订单类型',
          align: 'left',
          onExport: (records) => {
            return ['普通订单', '秒杀订单', '推广订单', '分销订单'][records]
          },
          filters: [
            {
              text: '普通订单',
              value: '0',
            },
            {
              text: '秒杀订单',
              value: '1',
            },
            {
              text: '分销订单',
              value: '3',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.orderType == value,
          customRender: (text) => {
            return ['普通订单', '秒杀订单', '推广订单', '分销订单'][text]
          },
        },
        {
          dataIndex: 'createTime',
          title: '下单时间',
          align: 'left',
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'right',
          width: '10%',
          typeData: ({ records, index }) => {
            const item = records
            return this.getColumnsButtonData({
              parent: records,
              item,
            })
          },
          // customRender: function customRender(text, records) {
          //   return (
          //     <div class="actionBox">
          //       {records.orderItemList.map((e, index) => {
          //         // 获取对应button按钮 子订单需要判断是否有退款
          //         let data = that.getColumnsButtonData({
          //           parent: records,
          //           item: e,
          //           index,
          //           childrenId: e.id
          //         })
          //         return (
          //           <div class="product-order-item">
          //             {data.map(el =>
          //               that.renderColumnButton({ orderItem: e, item: el })
          //             )}
          //           </div>
          //         )
          //       })}
          //     </div>
          //   )
          // }
        },
      ]
    },
    getColumnsButtonData({ parent, item } = {}) {
      let arr = [
        {
          name: '开票信息',
          display: parent.invoiceStatus && parent.invoiceStatus != 0,
          onClick: () => this.onInvoice(parent),
        },
        {
          name: '去处理',
          display: parent.fromType == '12' && parent.refundId,
          onClick: () => this.$router.push(`/orderProcessing/refund?id=${parent.refundId}`),
        },
        {
          type: 'pop',
          popTitle: '确认无库存，关闭订单',
          name: '无票库存',
          display: parent.status == '102' && parent.comboType != 0,
          onClick: () => this.onNoTicket(parent),
        },
        {
          type: 'pop',
          popTitle: '确认后，将通知用户核销使用',
          name: '确认有票',
          display: parent.status == '102' && parent.comboType != 0,
          onClick: () => this.onConfirmTicket(parent),
        },
        {
          name: '查看订单',
          display: true,
          onClick: () => this.onRowSelectScenicAreasTickets(parent, item.id),
        },
        {
          type: 'pop',
          popTitle: '确认关闭吗?',
          name: '关闭订单',
          display: parent.status == '0',
          onClick: () => this.onBatchDelete([parent]),
        },
      ]
      if (process.env.NODE_ENV === 'development') {
        return arr.filter((e) => e.display)
      } else {
        const buttonName = apiTool.getButtonName()
        return arr.filter((e) => e.display && buttonName.some((el) => e.name == el))
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },
     //开票信息
    onInvoice(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getInvoiceDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '开票信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
      // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    closeOrder(records) {},
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    // 无票库存
    onNoTicket(records) {
      return api.order.noTicket.call(this, {
        url: '/api/ticketOrder/confirmNoTicket',
        params: { orderId: records.id },
      })
    },
    // 确认有票
    onConfirmTicket(records) {
      return api.order.haveTicket.call(this, {
        url: '/api/ticketOrder/confirm',
        params: { orderId: records.id },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    // onRowSelect(parent, orderItemId) {
    //   const ext = [
    //     // 表格
    //     orderConfig.tableTickets,
    //     // 可选择表格
    //     orderConfig.tableTicketsSelect,
    //     // 基础信息
    //     orderConfig.baseTicket,
    //     // 用户表格
    //     orderConfig.tableTicketsInfo,
    //     // 第三方票务
    //     orderConfig.thirdPartyTicket,
    //     // 记录
    //     orderConfig.recordTicket,
    //   ]
    //   const onDetail = (e) => {
    //     orderConfig.open({
    //       data: e,
    //       params: {
    //         data: { ...e, showButton: e.fromType != '14' },
    //         // customOrderId: parent.id,
    //         // customOrderItemId: orderItemId,
    //       },
    //       executeFun: ext,
    //       onHidden: () => {
    //         this.getInit()
    //       },
    //       foot: ({ close, submit }) => {
    //         return [
    //           {
    //             name: '取消',
    //             onClick: close,
    //           },
    //         ]
    //       },
    //     })
    //   }
    //   if (parent.fromType == '14') {
    //     this.getPackageDetail(parent.id).then((detail) => {
    //       detail.orderItemList = detail.comboDetails.map((e) => ({
    //         ...e,
    //         productName: e.hotelRoomName ? e.productName + '/' + e.hotelRoomName : e.businessName + '/' + e.productName,
    //         productPic: '  ',
    //         totalPrice: e.salePrice,
    //       }))
    //       onDetail(detail)
    //     })
    //   } else {
    //     this.getDetailData(parent.id, orderItemId).then(onDetail)
    //   }
    // },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            console.log('data', data)
            downloadAction(`/api/ticketOrder/exportWl${getParams(data)}`, '景区商品.xlsx')
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.getRightButton()}
        records={this.records}
        filterRightButtonKey={['refresh', 'setting']}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.actionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
